import React, { useEffect } from "react"
import SEO from "../components/SEO/seo"

export default ({ location }) => {
  useEffect(() => {
    window &&
      window.location &&
      window.location.replace("https://www.facebook.com/groups/groupboss.io/")
  })
  return (
    <>
      <SEO
        title="Facebook Group Growth & Automation Family by Groupboss"
        description="Join official facebook group of Groupboss. Learn about Facebook group automation and other important information. Join, participate and learn from the experts."
        pathname={location.pathname}
      />

      <h1>Facebook Group Growth & Automation Family by Groupboss</h1>
      <p>
        Join official facebook group of Groupboss. Learn about Facebook group
        automation and other important information. Join, participate and learn
        from the experts.
      </p>
    </>
  )
}
